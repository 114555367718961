// eslint-disable-next-line @softarc/sheriff/encapsulation
import { UpdateConnectionRequestStatus$Params } from 'src/api/dso-portal/generated/fn/connection-requests/update-connection-request-status';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ButtonComponent } from '@eon-one/one-ui';
import { TranslateModule } from '@ngx-translate/core';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { StatusMeta } from '../status-indicator/status';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { StatusMetaProvider } from '@app/shared/util/status-meta-provider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
// eslint-disable-next-line @softarc/sheriff/encapsulation
import { ConnectionRequestsService } from 'src/api/dso-portal/generated/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
// eslint-disable-next-line @softarc/sheriff/encapsulation
import {
  ConnectionRequestDto,
  StatusType,
} from 'src/api/dso-portal/generated/models';
import { ErrorHandlingService, NotificationService } from '@app/core/services';
import { finalize } from 'rxjs';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

export interface ChangeStatusDialogData {
  id: string;
  status?: StatusType;
}

enum FORM_FIELD {
  SELECTED_STATUS = 'selectedStatus',
  NOTIFY_CUSTOMER = 'notifyCustomer',
}

@Component({
  selector: 'dso-change-status-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogModule,
    ButtonComponent,
    TranslateModule,
    IconButtonComponent,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    LoadingSpinnerComponent,
  ],
  templateUrl: './change-status-dialog.component.html',
})
export class ChangeStatusDialogComponent implements OnInit {
  public readonly dialogRef = inject(MatDialogRef<ChangeStatusDialogComponent>);
  public readonly data: ChangeStatusDialogData = inject(MAT_DIALOG_DATA);
  readonly #fb = inject(FormBuilder);
  readonly #destroyRef = inject(DestroyRef);
  readonly #connectionRequestService = inject(ConnectionRequestsService);
  readonly #errorHandlingService = inject(ErrorHandlingService);
  readonly #notificationService = inject(NotificationService);

  public readonly statusMetaInfos: StatusMeta[] =
    StatusMetaProvider.provideAll().filter(
      statusMeta =>
        statusMeta.status !== this.data.status &&
        statusMeta.status !== 'ARCHIVED'
    );
  public statusForm!: FormGroup;
  public readonly FORM_FIELD = FORM_FIELD;
  public isSaveInProgress = false;

  public ngOnInit(): void {
    this.statusForm = this.#fb.group({
      [FORM_FIELD.SELECTED_STATUS]: [undefined, Validators.required],
      [FORM_FIELD.NOTIFY_CUSTOMER]: [true, Validators.required],
    });
  }

  public onSubmit(): void {
    this.isSaveInProgress = true;
    const param = this.#buildUpdateStatusParams();
    this.#connectionRequestService
      .updateConnectionRequestStatus(param)
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        finalize(() => (this.isSaveInProgress = false))
      )
      .subscribe({
        next: (connectionRequest: ConnectionRequestDto) => {
          this.#notificationService.notify('SUCCESS');
          this.dialogRef.close(connectionRequest);
        },
        error: error => {
          this.#errorHandlingService.handleError(error, {
            showErrorSnackbar: true,
            shouldRedirect: false,
            msgTranslationIdentifier: 'SNACKBAR.ACTION_ERROR_MESSAGE',
          });
        },
      });
  }

  #buildUpdateStatusParams(): UpdateConnectionRequestStatus$Params {
    return {
      id: this.data.id,
      body: {
        status: this.statusForm.get(FORM_FIELD.SELECTED_STATUS)?.value,
        sendNotification: this.statusForm.get(FORM_FIELD.NOTIFY_CUSTOMER)
          ?.value,
      },
    };
  }
}
