import { Pipe, PipeTransform } from '@angular/core';
import { ModuleBase } from './module-base';
import { CUSTOM_APP_ICON } from '@app/core/services';
// eslint-disable-next-line @softarc/sheriff/encapsulation
import {
  FormModule,
  FormRequestType,
} from 'src/api/dso-portal/generated/models';

@Pipe({
  name: 'moduleIconSelector',
  standalone: true,
})
export class ModuleIconSelectorPipe
  extends ModuleBase
  implements PipeTransform
{
  transform(
    module: FormModule,
    requestType?: FormRequestType
  ): CUSTOM_APP_ICON {
    let icon: CUSTOM_APP_ICON;
    switch (module) {
      case 'ELECTRICITY': {
        if (this.isRequestWithTypeCommissioning(requestType)) {
          icon = CUSTOM_APP_ICON.MODULE_ELECTRICITY_COMMISSIONING;
          break;
        }
        icon = CUSTOM_APP_ICON.MODULE_ELECTRICITY;
        break;
      }
      case 'GAS': {
        icon = CUSTOM_APP_ICON.MODULE_GAS;
        break;
      }
      case 'WATER': {
        icon = CUSTOM_APP_ICON.MODULE_WATER;
        break;
      }
      case 'HEAT': {
        icon = CUSTOM_APP_ICON.MODULE_HEAT;
        break;
      }
      default: {
        this.throwUnsupportedError(module);
      }
    }
    return icon!;
  }
}
