import { TranslateModule } from '@ngx-translate/core';
import { Component, computed, input, InputSignal } from '@angular/core';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { StatusMetaProvider } from '@app/shared/util/status-meta-provider';
import { Size } from './size';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
// eslint-disable-next-line @softarc/sheriff/encapsulation
import { StatusType } from 'src/api/dso-portal/generated/models';

@Component({
  selector: 'dso-status-indicator',
  imports: [TranslateModule, IconButtonComponent, CommonModule, MatIconModule],
  standalone: true,
  templateUrl: './status-indicator.component.html',
  styleUrl: './status-indicator.component.scss',
})
export class StatusIndicatorComponent {
  public readonly status: InputSignal<StatusType> =
    input.required<StatusType>();
  public readonly size: InputSignal<Size> = input<Size>('small');
  public readonly total: InputSignal<number | undefined> = input<
    number | undefined
  >();

  public readonly statusMeta = computed(() =>
    StatusMetaProvider.provide(this.status())
  );
}
