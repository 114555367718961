import { Injectable } from '@angular/core';
import { IEnvironment, environment } from '@environments/index';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements IEnvironment {
  public get environment(): string {
    return environment.environment;
  }

  public get production(): boolean {
    return environment.production;
  }

  public get analyticsTagId(): string {
    return environment.analyticsTagId;
  }

  public get auth0ClientId(): string {
    return environment.auth0ClientId;
  }

  public get auth0Domain(): string {
    return environment.auth0Domain;
  }

  public get apiUrl(): string {
    return environment.apiUrl;
  }

  public get servicePortalUrl(): string {
    return environment.servicePortalUrl;
  }

  public get servicePortalApiUrl(): string {
    return environment.servicePortalApiUrl;
  }

  public get gtmId(): string {
    return environment.gtmId;
  }

  public get gtmAuth(): string | undefined {
    return environment.gtmAuth;
  }

  public get gtmPreview(): string | undefined {
    return environment.gtmPreview;
  }

  public get releaseNotesUrl(): string {
    return environment.releaseNotesUrl;
  }
}
