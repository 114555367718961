<button
  mat-mini-fab
  [attr.aria-label]="areaLabelButton()"
  [matTooltip]="tooltip()"
  [disabled]="disabled()"
  (click)="emitClick()"
  class="reset-filter-button">
  <mat-icon
    svgIcon="filter_reset"
    aria-hidden="false"
    [attr.aria-label]="ariaLabelIcon()" />
</button>
